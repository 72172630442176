import {
  MaybePendingUser,
  PublishedSection,
  PublishedZeck,
} from '../../../../../../types.ts';
import { useEffect, useState } from 'react';
import fromPairs from 'lodash/fromPairs.js';
import { UserAndCompany } from '../../../../../../userAndCompany/FetchUserAndCompany.js';
import useApi from '../../../../../../api/useApi.js';
import { CommentContent } from 'editor-content/CommentContent.js';
import useActionsForComments from './useActionsForComments.js';
import useCommentsUIState, { FilterState } from './useCommentsUIState.js';
import map from 'lodash/fp/map.js';
import mapValues from 'lodash/fp/mapValues.js';
import { Comment, CommentReply } from 'api-client/types.js';
import isFilteredCommentsExisty from './isFilteredCommentsExisty.js';
import filterComments from './filterComments.js';

export type CommentsStateForSection = {
  userName: string;
  sidebarOpen: boolean;
  isNewCommentFormOpen: boolean;
  canAddNewComment: boolean;
  sectionTitle: string;
  viewers: MaybePendingUser[];
  sectionId: string;
  setCommentSectionRef: React.RefCallback<HTMLElement>;
  setSectionContentRef: React.RefCallback<HTMLElement>;

  comments: CommentWithActions[];
  commentCount: number;

  scrollSectionIntoViewIfNeeded: () => void;

  openSectionComments: () => void;
  openNewComment: () => void;
  cancelNewComment: () => void;
  postComment: (
    content: CommentContent,
    asDirectMessage: boolean,
  ) => Promise<void>;
};

export type CommentsState = {
  sidebarOpen: boolean;
  totalCommentCount: number;
  filteredCommentsExist: boolean;
  isCommentsLoaded: boolean;
  filterState: FilterState;

  setZeckScrollContainerRef: React.RefCallback<HTMLElement>;

  setFilter: (filterState: FilterState) => void;
  closeComments: () => void;
  forSection: (publishedSection: PublishedSection) => CommentsStateForSection;
} | null;

function useLoadComments(publishedZeck: PublishedZeck) {
  const { getZeckViewers, getZeckComments } = useApi();

  const [viewers, setViewers] = useState<MaybePendingUser[]>([]);
  const [isCommentsLoaded, setIsCommentsLoaded] = useState(false);
  const [commentsAndDMsBySection, setCommentsAndDMsBySection] = useState<{
    [sectionId: string]: Comment[];
  }>(
    fromPairs(publishedZeck.sections.map((section) => [section.sectionId, []])),
  );

  const zeckId = publishedZeck.zeckId;
  const zeckDisabledComments = publishedZeck.settings.disableComments;

  useEffect(() => {
    if (zeckDisabledComments) {
      return;
    }

    getZeckComments(zeckId)
      .then((zeckComments) => {
        setCommentsAndDMsBySection(
          fromPairs(
            publishedZeck.sections.map((section) => [
              section.sectionId,
              zeckComments.filter(
                (comment) => comment.sectionId === section.sectionId,
              ),
            ]),
          ),
        );
      })
      .then(() => setIsCommentsLoaded(true));
    getZeckViewers(zeckId).then(setViewers);
  }, [
    getZeckComments,
    getZeckViewers,
    zeckId,
    zeckDisabledComments,
    publishedZeck.sections,
  ]);

  return {
    isCommentsLoaded,
    viewers,
    commentsAndDMsBySection,
    setCommentsAndDMsBySection,
  };
}

export function useCommentsForZeck(
  userAndCompany: UserAndCompany,
  publishedZeck: PublishedZeck,
): CommentsState | null {
  const { createComment } = useApi();

  const {
    isCommentsLoaded,
    viewers,
    commentsAndDMsBySection,
    setCommentsAndDMsBySection,
  } = useLoadComments(publishedZeck);

  const {
    setCommentSectionRef,
    setSectionContentRef,
    setZeckScrollContainerRef,
    openNewCommentFor,
    closeComments,
    isSidebarOpen,
    filterState,
    setFilter,
    forSection: uiStateForSection,
  } = useCommentsUIState();

  const {
    getActionsForComment: _getActionsForComment,
    getActionsForCommentReply: _getActionsForCommentReply,
  } = useActionsForComments(userAndCompany, {
    setComment: _setComment,
    setCommentReply: _setCommentReply,
    unresolve: (commentToUnresolve) => {
      _updateCommentResolution(commentToUnresolve, false);
    },
    resolve: (commentToResolve) => {
      _updateCommentResolution(commentToResolve, true);
    },
    removeComment: (commentToRemove) => {
      setCommentsAndDMsBySection(
        mapValues((comments) =>
          comments
            .filter((comment) => comment.id !== commentToRemove.id)
            .map((comment) => {
              return {
                ...comment,
                replies: comment.replies.filter(
                  (comment) => comment.id !== commentToRemove.id,
                ),
              };
            }),
        ),
      );
    },
    toggleStar: ({ id }) => {
      _updateStarStatus({ id });
    },
  });

  if (publishedZeck.settings.disableComments) {
    return null;
  }

  function _setCommentReply(updatedReply: CommentReply) {
    setCommentsAndDMsBySection(
      mapValues((comments) =>
        comments.map((comment) => {
          if (comment.id !== updatedReply.parentId) return comment;

          return {
            ...comment,
            replies: comment.replies.map((reply) =>
              reply.id === updatedReply.id ? updatedReply : reply,
            ),
          };
        }),
      ),
    );
  }

  function _setComment(comment: Comment) {
    const comments = commentsAndDMsBySection[comment.sectionId];
    if (!comments) {
      throw new Error('comments not loaded');
    }

    setCommentsAndDMsBySection({
      ...commentsAndDMsBySection,
      [comment.sectionId]: comments.map((c) =>
        c.id === comment.id ? comment : c,
      ),
    });
  }

  function _updateCommentResolution(
    commentToUpdateResolvedFor: { id: string },
    isResolved: boolean,
  ) {
    setCommentsAndDMsBySection(
      mapValues(
        map((comment) => ({
          ...comment,
          resolved:
            commentToUpdateResolvedFor.id === comment.id
              ? isResolved
              : comment.resolved,
        })),
      ),
    );
  }

  function _updateStarStatus(commentToUpdateStarredFor: { id: string }) {
    setCommentsAndDMsBySection(
      mapValues(
        map((comment) => ({
          ...comment,
          starred:
            commentToUpdateStarredFor.id === comment.id
              ? !comment.starred
              : comment.starred,
        })),
      ),
    );
  }

  const totalCommentCount = Object.values(commentsAndDMsBySection).reduce(
    (count, comments) =>
      count +
      comments.length +
      comments.reduce((count, comment) => count + comment.replies.length, 0),
    0,
  );

  return {
    isCommentsLoaded,
    filterState,
    totalCommentCount: totalCommentCount,
    filteredCommentsExist: isFilteredCommentsExisty(
      filterState,
      commentsAndDMsBySection,
    ),
    setFilter,
    sidebarOpen: isSidebarOpen,
    setZeckScrollContainerRef,
    closeComments,
    forSection(publishedSection: PublishedSection): CommentsStateForSection {
      const sectionId = publishedSection.sectionId;

      const {
        isNewCommentFormOpen,
        openSectionComments,
        closeNewComment,
        scrollSectionIntoViewIfNeeded,
        canAddNewComment,
      } = uiStateForSection(sectionId);

      function _appendComment(comment: Comment) {
        const comments = commentsAndDMsBySection[sectionId];
        if (!comments) {
          throw new Error('comments not loaded');
        }

        setCommentsAndDMsBySection({
          ...commentsAndDMsBySection,
          [comment.sectionId]: [comment, ...comments],
        });
      }

      const commentsForSection = commentsAndDMsBySection[sectionId] || [];

      const userName =
        userAndCompany.user.firstName + ' ' + userAndCompany.user.lastName;

      const { activeComments, resolvedComments, starredComments } =
        filterComments(commentsForSection);

      const filteredComments = (() => {
        switch (filterState) {
          case 'resolved':
            return resolvedComments;
          case 'starred':
            return starredComments;
          case null:
            return activeComments;
        }
      })();

      const comments = filteredComments.map((comment): CommentWithActions => {
        return {
          ...comment,
          replies: comment.replies.map((commentReply) => ({
            ...commentReply,
            actions: _getActionsForCommentReply(commentReply),
          })),
          actions: _getActionsForComment(comment),
        };
      });

      const addComment = async (
        content: CommentContent,
        asDirectMessage = false,
      ) => {
        _appendComment(
          await createComment(sectionId, content, asDirectMessage),
        );

        closeNewComment();
      };

      const commentCount =
        activeComments.length +
        activeComments.reduce((count, c) => count + c.replies.length, 0);

      return {
        sectionId: sectionId,
        sidebarOpen: isSidebarOpen, // used to make section title disappear when sidebar is open in mobile view
        isNewCommentFormOpen,
        userName,
        sectionTitle: publishedSection.title,
        openNewComment: () => openNewCommentFor(sectionId),
        cancelNewComment: closeNewComment,

        setCommentSectionRef: setCommentSectionRef(sectionId),
        setSectionContentRef: setSectionContentRef(sectionId),
        scrollSectionIntoViewIfNeeded,

        comments,
        commentCount,
        viewers,
        openSectionComments: openSectionComments,
        postComment: addComment,
        canAddNewComment,
      };
    },
  };
}

export type CommentReplyWithActions = CommentReply & {
  actions: {
    edit?: (content: CommentContent) => Promise<void>;
    delete?: () => Promise<void>;
  };
};

export type CommentWithActions = Omit<Comment, 'replies'> & {
  replies: CommentReplyWithActions[];
  actions: {
    toggleStar: (() => void) | undefined;
    edit?: (content: CommentContent) => Promise<void>;
    delete?: () => Promise<void>;
    reply: (content: CommentContent) => Promise<void>;
    resolve?: () => void;
    unresolve?: () => void;
  };
};
