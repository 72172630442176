import { RequestError } from '../makeMakeApiRequest.js';
import { Comment, CommentReply } from '../../types/Comment.js';
import { CommentContent } from 'editor-content/CommentContent.js';
import PublishedCommentApiClient from './PublishedCommentApiClient.js';

const createCommentApi = (
  ApiClient: typeof PublishedCommentApiClient,
  accessToken: string | null,
) => {
  return {
    async getZeckComments(zeckId: string) {
      const response = await ApiClient.getAll({
        params: { zeckId },
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
      });

      switch (response.status) {
        case 200: {
          return response.body;
        }
        // adapting from ts-rest returning error codes
        // to our code that expects thrown errors
        default: {
          throw new RequestError(
            'publishedCommentGetAll',
            '',
            response.status,
            response,
          );
        }
      }
    },

    createComment: async (
      sectionId: string,
      content: CommentContent,
      asDirectMessage: boolean,
    ) => {
      const response = await ApiClient.create({
        params: { sectionId },
        body: { content, directMessage: asDirectMessage },
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
      });

      switch (response.status) {
        case 201: {
          return response.body;
        }
        // adapting from ts-rest returning error codes
        // to our code that expects thrown errors
        default: {
          throw new RequestError(
            'publishedCommentCreate',
            '',
            response.status,
            response,
          );
        }
      }
    },

    resolveComment: async (commentId: string): Promise<void> => {
      const response = await ApiClient.tagsUpdate({
        params: { publishedCommentId: commentId },
        body: { resolved: true },
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
      });

      switch (response.status) {
        case 200: {
          return;
        }
        // adapting from ts-rest returning error codes
        // to our code that expects thrown errors
        default: {
          throw new RequestError(
            'resolveComment',
            '',
            response.status,
            response,
          );
        }
      }
    },
    unresolveComment: async (commentId: string): Promise<void> => {
      const response = await ApiClient.tagsUpdate({
        params: { publishedCommentId: commentId },
        body: { resolved: false },
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
      });

      switch (response.status) {
        case 200: {
          return;
        }
        // adapting from ts-rest returning error codes
        // to our code that expects thrown errors
        default: {
          throw new RequestError(
            'unresolveComment',
            '',
            response.status,
            response,
          );
        }
      }
    },
    // we could consider just exposing an add/remove tag to this layer
    // but the goal here is to just hide the tag implementation from
    // as much of the UI as possible, but it might get absurd if we have
    // 3+ types of tags.
    starComment: async (commentId: string): Promise<void> => {
      const response = await ApiClient.tagsUpdate({
        params: { publishedCommentId: commentId },
        body: { starred: true },
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
      });

      switch (response.status) {
        case 200: {
          return;
        }
        // adapting from ts-rest returning error codes
        // to our code that expects thrown errors
        default: {
          throw new RequestError(
            'resolveComment',
            '',
            response.status,
            response,
          );
        }
      }
    },
    unStarComment: async (commentId: string): Promise<void> => {
      const response = await ApiClient.tagsUpdate({
        params: { publishedCommentId: commentId },
        body: { starred: false },
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
      });

      switch (response.status) {
        case 200: {
          return;
        }
        // adapting from ts-rest returning error codes
        // to our code that expects thrown errors
        default: {
          throw new RequestError(
            'resolveComment',
            '',
            response.status,
            response,
          );
        }
      }
    },

    updateComment: async (
      comment: Comment,
      newContent: CommentContent,
    ): Promise<Comment> => {
      const response = await ApiClient.update({
        params: { publishedCommentId: comment.id },
        body: { content: newContent },
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
      });

      switch (response.status) {
        case 200: {
          return response.body;
        }
        // adapting from ts-rest returning error codes
        // to our code that expects thrown errors
        default: {
          throw new RequestError(
            'publishedCommentUpdate',
            '',
            response.status,
            response,
          );
        }
      }
    },

    deleteComment: async (comment: Comment): Promise<void> => {
      const response = await ApiClient.delete({
        params: { publishedCommentId: comment.id },
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
      });

      switch (response.status) {
        case 200: {
          return;
        }
        // adapting from ts-rest returning error codes
        // to our code that expects thrown errors
        default: {
          throw new RequestError(
            'publishedCommentDelete',
            '',
            response.status,
            response,
          );
        }
      }
    },

    createCommentReply: async (
      comment: Comment,
      replyContent: CommentContent,
    ): Promise<CommentReply> => {
      const response = await ApiClient.replyCreate({
        params: { publishedCommentId: comment.id },
        body: { content: replyContent },
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
      });

      switch (response.status) {
        case 201: {
          return response.body;
        }
        // adapting from ts-rest returning error codes
        // to our code that expects thrown errors
        default: {
          throw new RequestError(
            'publishedCommentReplyCreate',
            '',
            response.status,
            response,
          );
        }
      }
    },

    updateCommentReply: async (
      reply: CommentReply,
      newContent: CommentContent,
    ) => {
      const response = await ApiClient.replyUpdate({
        params: { publishedCommentReplyId: reply.id },
        body: { content: newContent },
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
      });

      switch (response.status) {
        case 200: {
          return response.body;
        }
        // adapting from ts-rest returning error codes
        // to our code that expects thrown errors
        default: {
          throw new RequestError(
            'publishedCommentReplyUpdate',
            '',
            response.status,
            response,
          );
        }
      }
    },

    deleteCommentReply: async (commentReply: CommentReply): Promise<void> => {
      const response = await ApiClient.replyDelete({
        params: { publishedCommentReplyId: commentReply.id },
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
      });

      switch (response.status) {
        case 200: {
          return;
        }
        // adapting from ts-rest returning error codes
        // to our code that expects thrown errors
        default: {
          throw new RequestError(
            'publishedCommentReplyDelete',
            '',
            response.status,
            response,
          );
        }
      }
    },
  };
};

export default createCommentApi;
